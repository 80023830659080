import Config from 'config/Config';
import Cookies from 'js-cookie';
import { PATHNAMES } from 'lib/constants/pathnames';
import React from 'react';

let idleTime = eval(Config.IDLE_DURATION);

const useWindowOnIdle = () => {
  React.useEffect(() => {
    const checkToken = () => {
      const token = Cookies.get('_access_auth');
      if (!token) location.replace(PATHNAMES.SIGN_OUT);
    };

    checkToken();
    const interval = setInterval(checkToken, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  React.useEffect(() => {
    let timeout;

    function clearCookies() {
      location.replace(PATHNAMES.SIGN_OUT);
    }

    function resetTimer() {
      const token = Cookies.get('_access_auth');
      if (!token) clearCookies();
      else {
        clearTimeout(timeout);
        timeout = setTimeout(clearCookies, idleTime);
      }
    }

    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);

    timeout = setTimeout(clearCookies, idleTime);

    return () => {
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
      clearTimeout(timeout);
    };
  }, []);

  return {};
};

export default useWindowOnIdle;
