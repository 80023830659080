import React from 'react';
import Cookies from 'js-cookie';

import httpService from 'service/httpService';
import { User } from 'service/userService';
import { MessageFeedback } from 'modules/components/Feedback/Feedback';
import useInternetConnection from 'hooks/useInternetConnection';
import { PATHNAMES } from 'lib/constants/pathnames';

const token = Cookies.get('_access_auth');
const UserContext = React.createContext({});

const UserContextProvider = ({ children }) => {
  const userService = new User();
  const { isOnline } = useInternetConnection();
  const [accessToken, setAccessToken] = React.useState(token);
  const refreshToken = Cookies.get('_refresh_auth');

  const [user, setUser] = React.useState(null);
  const [invitations, setInvitations] = React.useState({});
  const [refetch, setRefetch] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setAccessToken(Cookies.get('_access_auth'));
  }, [refetch]);

  React.useEffect(() => {
    if (!isOnline) return;
    if (!accessToken) return;
    getUser();
  }, [accessToken, refetch, isOnline]);

  async function getUser() {
    try {
      const { data } = await userService.getCurrentUser();
      setUser(data?.data);
      setIsLoading(false);
    } catch (ex) {
      httpService.errorHandler(ex);
      setIsLoading(false);
    }
  }

  function handleLogout() {
    setUser(null);
    location.replace(PATHNAMES.SIGN_OUT);
  }

  return (
    <UserContext.Provider
      value={{
        user,
        accessToken,
        refreshToken,
        setUser,
        setRefetch,
        handleLogout,
        isFetching: isLoading,
        invitations,
        setInvitations,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
