import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  TextField,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import BTNLoader from 'lib/components/Preloaders/Btn';
import { PATHNAMES } from 'lib/constants/pathnames';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import httpService from 'service/httpService';
import userService from 'service/userService';
import authValidation from 'validations/auth/authValidation';

const DeleteAccountModal = ({ isOpen, onClose }) => {
  const [isDelete, setIsDelete] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const { handleSubmit, errors, control } = useForm({
    mode: 'all',
    resolver: yupResolver(authValidation.DELETE_ACCOUNT),
    defaultValues: { password: '' },
  });

  function handleClose() {
    setIsDelete(false);
    onClose();
  }

  if (!isOpen) return null;
  if (isDelete)
    return (
      <Dialog
        open={isOpen}
        maxWidth="sm"
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(save),
          className: 'w-100',
          style: { maxWidth: 550 },
        }}>
        <header className="d-flex justify-content-between align-items-center gap-4 p-3 py-2 pe-2">
          <div className="fs-5 fw-medium">Delete Account</div>
          <div>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
        </header>
        <Divider className="bg-secondary-subtle" />
        <DialogContent>
          <div className="mb-5">
            <label htmlFor="">
              Enter password <span className="text-danger">*</span>
            </label>
            <Controller
              name="password"
              control={control}
              render={(props) => (
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={props.value}
                  type={'password'}
                  onChange={(e) => props.onChange(e.target.value)}
                  helperText={errors['password']?.message}
                  error={!!errors['password']}
                />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} type="button">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={isLoading}
            startIcon={isLoading ? <BTNLoader /> : null}>
            Delete Account
          </Button>
        </DialogActions>
      </Dialog>
    );
  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      PaperProps={{
        className: 'w-100',
        style: { maxWidth: 550 },
      }}>
      <header className="d-flex justify-content-between align-items-center gap-4 p-3 py-2 pe-2">
        <div className="fs-5 fw-medium">Delete Account</div>
        <div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
      </header>
      <Divider className="bg-secondary" />
      <DialogContent className="py-3">
        <div className="mb-3">
          Are you sure you want to delete your account?
        </div>
        <div className="mb-3">
          Please be aware that this action is irreversible and will result in
          the following:
        </div>
        <ol>
          <li className="mb-3">
            <div className="fw-medium"> Permanent Loss of Data:</div>
            <ul>
              <li>
                All your personal data and settings will be permanently deleted.
              </li>
              <li>
                You will no longer have access to any files, messages, or
                history associated with your account.
              </li>
            </ul>
          </li>
          <li className="mb-3">
            <div className="fw-medium">Loss of Organizations Created:</div>
            <ul>
              <li>
                Any organizations you have created will be permanently deleted.
                All data, projects, and records associated with these
                organizations will be lost.{' '}
              </li>
              <li>
                Members of these organizations will lose access to shared
                resources.
              </li>
            </ul>
          </li>
        </ol>
        <div>click proceed to continue</div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} type="button">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsDelete(true)}>
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );

  async function save(formData) {
    try {
      setIsLoading(true);
      await userService.deleteAccount(formData);
      httpService.toast.success('Account deletion successful');
      setTimeout(() => window.location.replace(PATHNAMES.SIGN_OUT), 1000);
    } catch (ex) {
      setIsLoading(false);
      httpService.errorHandler(ex);
    }
  }
};

export default DeleteAccountModal;
