import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

export const PreloaderTableRow = ({ cols = 4, items = [], isLoading }) => {
  const _cols = [];
  for (let i = 0; i < cols; i++) {
    _cols.push(i);
  }

  if (isLoading && !items?.length)
    return (
      <React.Fragment>
        {[1, 2, 3, 4, 5]?.map((item) => (
          <TableRow key={item}>
            {_cols.map((item) => (
              <TableCell key={item}>
                <Skeleton variant="text" />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </React.Fragment>
    );
  return null;
};

const PreloaderTable = () => (
  <TableContainer>
    <Paper className="my-3 border">
      <Table>
        <TableHead>
          <TableRow>
            {[1, 2, 3, 4, 5].map((item) => (
              <TableCell key={item}>
                <Skeleton variant="text" />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[1, 2, 3, 4, 5]?.map((item) => (
            <TableRow key={item}>
              {[1, 2, 3, 4, 5].map((item) => (
                <TableCell key={item}>
                  <Skeleton variant="text" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  </TableContainer>
);

export default PreloaderTable;
