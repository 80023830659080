import axios from 'axios';
import Config from 'config/Config';
import Cookies from 'js-cookie';
import { PATHNAMES } from 'lib/constants/pathnames';
import { toast } from 'react-toastify';

const accessToken = Cookies.get('_access_auth');
const refreshToken = Cookies.get('_refresh_auth');

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
  }
  return Promise.reject(error);
});

const http = axios.create({
  baseURL: `${Config.baseUrl}/api/v1`,
});

http.defaults.headers = {
  Authorization: `Bearer ${accessToken}`,
};

function showSuccess(message, setFeedback) {
  toast.success(`Success: ${message}`);
}

function errorHandler(ex, show = true) {
  const res = ex?.response;
  const error = ex?.response?.data;
  let errorMessage = '';

  if (Array?.isArray(error?.message)) {
    errorMessage = Object.values(error?.message[0])[0];
  } else if (typeof error?.message === 'object') {
    errorMessage = Object.values(error?.message)[0];
  } else if (
    typeof error?.message === 'string' &&
    error?.message?.length < 255
  ) {
    errorMessage = String(error?.message);
  } else if (typeof error === 'string' && error?.length < 255) {
    errorMessage = error;
  }

  if (res && res.status >= 400 && res.status < 500) {
    toast.error(errorMessage);
  } else if (res && res.status >= 500) {
    toast.error('An unrespected error occurred.');
  } else if (typeof res === 'string') {
    toast.error(res);
  }
}

function showFeedback(ex, setFeedback) {
  errorHandler(ex);
}

export default {
  get: http.get,
  post: http.post,
  put: http.put,
  delete: http.delete,
  all: http.all,
  axios: http,
  showFeedback,
  showSuccess,
  errorHandler,
  toast: toast,
};
