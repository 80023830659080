import React from 'react';

const AccountingContext = React.createContext({});

const initialData = { data: [], totalDocs: 0, totalPages: 0 };
const AccountingContextProvider = ({ children }) => {
  const [customer, setCustomer] = React.useState(null);
  const [customers, setCustomers] = React.useState({});
  const [topCustomers, setTopCustomers] = React.useState([]);
  const [budget, setBudget] = React.useState(null);
  const [budgets, setBudgets] = React.useState({});
  const [inflow, setInflow] = React.useState(null);
  const [inflows, setInflows] = React.useState({});
  const [outflow, setOutflow] = React.useState(null);
  const [outflows, setOutflows] = React.useState({});
  const [vendor, setVendor] = React.useState(null);
  const [vendors, setVendors] = React.useState({});
  const [quotation, setQuotation] = React.useState({});
  const [quotations, setQuotations] = React.useState({});
  const [journals, setJournals] = React.useState(initialData);
  const [recurringJournal, setRecurringJournal] = React.useState(initialData);
  const [accounts, setAccounts] = React.useState([]);
  return (
    <AccountingContext.Provider
      value={{
        customer,
        setCustomer,
        customers,
        setCustomers,
        topCustomers,
        setTopCustomers,
        budget,
        setBudget,
        budgets,
        setBudgets,
        inflow,
        setInflow,
        inflows,
        setInflows,
        outflow,
        setOutflow,
        outflows,
        setOutflows,
        vendor,
        setVendor,
        vendors,
        setVendors,
        quotation,
        setQuotation,
        quotations,
        setQuotations,
        accounts,
        setAccounts,
        journals,
        setJournals,
        recurringJournal,
        setRecurringJournal,
      }}>
      {children}
    </AccountingContext.Provider>
  );
};

export { AccountingContext, AccountingContextProvider };
