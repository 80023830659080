import React from 'react';
import Profile from './profile';
import PasswordAndSecurity from './password-and-security';
import Invitations from './invitations';
import { Redirect, Route, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core';
import Header from '../components/header';
import { PATHNAMES } from 'lib/constants/pathnames';
import AccountDetails from './account-details';
import useUserData from 'hooks/useUserData';
import { useHistory } from 'react-router-dom';
import ReturnBtn from 'modules/HumanResources/Employees/AddEmployees/components/ReturnBtn';
import DeleteAccountModal from 'modules/Settings/profile/components/deleteAccount';

const AccountSettings = () => {
  const { user } = useUserData();
  const history = useHistory();
  const pathname = window.location.pathname;
  const [isOpen, setIsOpen] = React.useState(false);

  const routes = [
    {
      title: 'Personal Profile',
      path: PATHNAMES.PROFILE,
      component: Profile,
      exact: true,
    },
    {
      title: 'Password and Security',
      path: PATHNAMES.PASSWORD_AND_SECORIRY,
      component: PasswordAndSecurity,
      exact: true,
    },
    {
      title: 'User Invitation',
      path: PATHNAMES.INVITATIONS,
      component: Invitations,
      exact: true,
    },
    {
      title: 'User Account Details',
      path: PATHNAMES.ACCOUNT_DETAILS,
      component: AccountDetails,
      exact: true,
    },
  ];

  const NAVIGATION_LINKS = [
    {
      id: 1,
      name: 'Personal Information',
      link: PATHNAMES.PROFILE,
    },
    {
      id: 2,
      name: 'Account Details',
      link: PATHNAMES.ACCOUNT_DETAILS,
    },
    {
      id: 3,
      name: 'Password and Security',
      link: PATHNAMES.PASSWORD_AND_SECORIRY,
    },
    {
      id: 4,
      name: 'Invitations',
      link: PATHNAMES.INVITATIONS,
    },
  ];

  const LINK = `${location?.pathname}${location.search}`;

  React.useEffect(() => {
    if (!user) return;
    if (!user?.isVerified) {
      history.replace(
        `${PATHNAMES.ACCOUNT_VERIFICATION}?type=new&redirect=${LINK}`,
      );
    }
  }, [user]);

  return (
    <React.Fragment>
      <Header />
      <div className="container mt-5 mb-5">
        <div className="mb-4">
          <ReturnBtn />
        </div>
        <Grid container spacing={3}>
          <Grid item sm={3}>
            <div className="fw-semibold fs-3 mb-4">Settings</div>
            {NAVIGATION_LINKS.map((data) => (
              <Link
                key={data.id}
                to={data?.link}
                className={`d-block mb-3 ${
                  pathname?.includes(data?.link)
                    ? 'text-primary fw-semibold'
                    : 'text-dark'
                }`}
                replace={true}>
                {data?.name}
              </Link>
            ))}
            <Link
              to={'#'}
              className="text-danger"
              onClick={() => setIsOpen(true)}>
              Delete Account
            </Link>
          </Grid>
          <Grid item sm={9}>
            <Box>
              <Switch>
                {routes.map(({ component: Component, ...route }, i) => (
                  <Route
                    key={i}
                    {...route}
                    render={(props) => <Component {...props} />}
                  />
                ))}
                <Redirect from="/account" to={PATHNAMES.PROFILE} />
              </Switch>
            </Box>
          </Grid>
        </Grid>
      </div>
      <DeleteAccountModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </React.Fragment>
  );
};

export default AccountSettings;
