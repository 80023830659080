import * as Yup from 'yup';

const CREATE_ORGANIZATION = Yup.object({
  name: Yup.string().required().label('Business Name'),
  phone: Yup.string().label('Business Phone Number'),
  email: Yup.string().email().label('Business Email'),
  currency: Yup.string().required().label('Currency'),
  // state: Yup.string().required().label('State'),
  // lga: Yup.string().required().label('LGA'),
  // number: Yup.string().required().label('House Number'),
  // street: Yup.string().required().label('Street'),
  // city: Yup.string().required().label('City'),
  // businessStartDate: Yup.date(),
  // country: Yup.string().required().label('Country'),
  // businessType: Yup.string().required().label('Business Type'),
  // businessSector: Yup.string().label('Business Sector'),
});

const VERIFICATION = Yup.object({
  otp: Yup.string()
    .trim()
    .matches(/^[0-9]*$/, 'Invalid OTP format')
    .required('OTP is required for verification'),
});
const DELETE_ACCOUNT = Yup.object({
  password: Yup.string()
    .trim()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
});

const SIGN_UP = Yup.object({
  firstName: Yup.string()
    .trim()
    .matches(/^[a-zA-z]*$/, 'Numbers are not allowed')
    .required('First name is required'),
  lastName: Yup.string()
    .trim()
    .matches(/^[a-zA-z]*$/, 'Digits not allowed')
    .required('Last name is required'),
  email: Yup.string()
    .trim()
    .email('Email address is invalid')
    .required('Email address is required'),
  phone: Yup.string().trim().required().label('Phone Number'),
  terms: Yup.boolean().oneOf([true]).required().label('Terms'),
  password: Yup.string()
    .trim()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .trim()
    .required()
    .label('Confirm Password')
    .oneOf([Yup.ref('password')], 'Passwords does not match'),
});

const SIGN_IN = Yup.object({
  email: Yup.string()
    .trim()
    .email('Email must be valid')
    .required('Email address is required'),
  password: Yup.string()
    .trim()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
});

const FORGOT_PASSWORD = Yup.object({
  email: Yup.string()
    .trim()
    .email('Email must be valid')
    .required('Email address is required'),
});

const RESET_PASSWORD = Yup.object({
  password: Yup.string()
    .trim()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .trim()
    .required()
    .label('Confirm Password')
    .oneOf([Yup.ref('password')], 'Passwords does not match'),
});

export default {
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  CREATE_ORGANIZATION,
  VERIFICATION,
  SIGN_UP,
  SIGN_IN,
  DELETE_ACCOUNT,
};
