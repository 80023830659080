import React from 'react';

const ReportContext = React.createContext({});

const ReportContextProvider = ({ children }) => {
  const [logs, setLogs] = React.useState({});

  return (
    <ReportContext.Provider
      value={{
        logs,
        setLogs,
      }}>
      {children}
    </ReportContext.Provider>
  );
};

export { ReportContext, ReportContextProvider };
