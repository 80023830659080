import capitalize from 'lib/helpers/StringCapitalize';
import http from './httpService';
import moment from 'moment';
import _ from 'lodash';
const orgId = localStorage.getItem('organizationId');

export class User {
  getOrgID() {
    return localStorage.getItem('organizationId');
  }
  removeOrgID() {
    return localStorage.removeItem('organizationId');
  }
  getTaxRates() {
    // return http.get('/meta/tax-rates');
    return http.get('/meta/tax-rates/single?countryCode=234');
  }
  getVAT() {
    const vat = localStorage.getItem('[vat]');
    return parseFloat(vat || 0);
  }
  setOrgID(id) {
    return localStorage.setItem('organizationId', id);
  }
  createOrganization(data) {
    const businessData = orgFormData(data);
    return http.post(`/user/cp/account/organizations`, businessData);
  }

  onBoard() {
    return http.put(`/user/cp/org/${orgId}/onboarded`, {});
  }
  onBoarding(data) {
    const payload = {
      step: data?.step,
      skip: false,
      done: true,
    };
    return http.put(`/user/cp/account/onboarding`, payload);
  }
  updateOrganization(data) {
    const formData = new FormData();
    if (data?.image) formData.append('logoUpload', data?.image);
    formData.append('name', data?.name);
    formData.append('type', data?.type);
    formData.append('phone', data?.phone);
    formData.append('address[address]', data?.address?.address);
    formData.append('address[city]', data?.address?.city);
    formData.append('address[country]', data?.address?.country);
    formData.append('address[state]', data?.address?.state);
    if (data?.fiscalYear)
      formData.append('fiscalYearStartMonth', data?.fiscalYear);
    if (data?.registrationNumber)
      formData.append('registrationNumber', data?.registrationNumber);
    if (data?.sector) formData.append('sector', data?.sector);
    if (data?.firsTin) formData.append('firstin', data?.firsTin);
    if (data?.jbtTin) formData.append('jtbtin', data?.jbtTin);
    if (data?.remitState) formData.append('tinState', data?.remitState);
    if (data?.stateTin) formData.append('tinlirs', data?.stateTin);
    return http.put(`/user/cp/org/${orgId}`, formData);
  }
  updateOrganizationLogo(file) {
    const formData = new FormData();
    formData.append('logoUpload', file);
    const route = '/user/cp/org/' + orgId;
    return http.put(route, formData);
  }
  updateOrganizationSign(file) {
    const formData = new FormData();
    formData.append('signatureUpload', file);
    const route = '/user/cp/org/' + orgId;
    return http.put(route, formData);
  }
  updateValuationMethod(method) {
    const formData = {
      valuationMethod: method,
    };
    const route = '/user/cp/org/' + orgId;
    return http.put(route, formData);
  }
  updateOrganizationCustomization(data) {
    const formData = {
      customization: {
        invoice: {
          option: data?.invoice?.option,
          accentColor: data?.invoice?.color || '#1f53d7',
        },
        letterHead: {
          option: data?.letterHead?.option,
          accentColor: data?.letterHead?.color || '#1f53d7',
        },
        idCard: {
          option: data?.idCard?.option,
          accentColor: data?.idCard?.color || '#1f53d7',
        },
        memo: {
          option: data?.memo?.option,
          accentColor: data?.memo?.color || '#1f53d7',
        },
      },
    };
    const route = '/user/cp/org/' + orgId;
    return http.put(route, formData);
  }
  organizationPayment(data) {
    const formData = {
      payment: {
        isEnabled: data?.isEnabled,
        defaultAccount: {
          bankName: data?.bankName || '',
          bankAccountNumber: data?.bankAccountNumber || '',
          bankAccountName: data?.bankAccountName || '',
        },
      },
    };
    if (data?.config) {
      formData.payment.paystack = {
        config: {
          isEnabled: data?.config?.isEnabled || '',
          publicKey: data?.config?.publicKey || '',
          secretKey: data?.config?.secretKey || '',
        },
      };
      Object.keys(formData.payment.paystack.config).forEach((key) => {
        if (!formData.payment.paystack.config[key])
          delete formData.payment.paystack.config[key];
      });
    }
    Object.keys(formData.payment.defaultAccount).forEach((key) => {
      if (!formData.payment.defaultAccount[key])
        delete formData.payment.defaultAccount;
    });

    const route = '/user/cp/org/' + orgId;
    return http.put(route, formData);
  }
  getCurrentOrg() {
    return http.get(`/user/cp/org/${orgId}`);
  }
  getOrgs() {
    return http.get(`/user/cp/account/organizations`);
  }
  getInvites(all = '', page) {
    return all
      ? http.get(`/user/cp/account/invitations?page=${page}`)
      : http.get(`/user/cp/account/invitations?status=PENDING`);
  }
  acceptInvite(id) {
    return http.put(`/user/cp/account/invitations/accept?id=${id}`, {
      id,
    });
  }
  rejectInvite(id) {
    return http.put(`/user/cp/account/invitations/decline?id=${id}`, {
      id,
    });
  }
  getCurrentUser() {
    return http.get(`/user/cp/account`);
  }
  getAuthAdministrator() {
    return http.get(`/user/cp/org/${orgId}/administrators/me`);
  }
  updateCurrentUser(data) {
    Object.keys(data).forEach((key) => {
      if (!data[key]) delete data[key];
    });
    delete data['email'];
    return http.put(`/user/cp/account`, data);
  }
  changePassword(data) {
    return http.put(`/user/cp/account/password-change`, data);
  }
  deleteAccount(data) {
    return http.delete(`/user/cp/account/`, { data: { ...data } });
  }
  getRoles() {
    const route = `/user/cp/org/${orgId}/roles`;
    return http.get(route);
  }

  updateUser(data, id) {
    const formData = {
      ...data,
      firstName: capitalize(data?.firstName),
      lastName: capitalize(data?.lastName),
      position: capitalize(data?.position),
    };
    const route = `/user/cp/org/${orgId}/administrators?id=${id}`;
    return http.put(route, formData);
  }
  getUsers() {
    const route = `/user/cp/org/${orgId}/administrators`;
    return http.get(route);
  }
  getOneUser(id) {
    const route = `/user/cp/org/${orgId}/administrators/single?id=${id}`;
    return http.get(route);
  }

  deactivateUser(id) {
    const route = `/user/cp/org/${orgId}/administrators/deactivate?id=${id}`;
    return http.put(route, { id });
  }
  activateUser(id) {
    const route = `/user/cp/org/${orgId}/administrators/activate?id=${id}`;
    return http.put(route, { id });
  }
  createUser(data) {
    const formData = {
      ...data,
      firstName: capitalize(data?.firstName),
      lastName: capitalize(data?.lastName),
      position: capitalize(data?.position),
    };
    const route = `/user/cp/org/${orgId}/invitations`;
    return http.post(route, formData);
  }
  getUsersInvitations() {
    const route = `/user/cp/org/${orgId}/invitations`;
    return http.get(route);
  }
  resendInvitation(id) {
    const route = `/user/cp/org/${orgId}/invitations?id=${id}`;
    return http.put(route, { id });
  }
  cancelInvitation(id) {
    const route = `/user/cp/org/${orgId}/invitations/cancel?id=${id}`;
    return http.post(route, { id });
  }
  getPermissions() {
    const route = '/meta/access/permissions';
    return http.get(route);
  }
  getDiscount() {
    const params = {};
    return http.get('/user/cp/marketing/discounts/me', { params });
  }
}

export class Branch {
  create(data, id = '') {
    const formData = {
      ..._.pick(data, ['name', 'description', 'dateCreated']),
      address: {},
    };
    if (data?.selectedEmployee)
      formData['managerId'] = data?.selectedEmployee?.id;
    if (data?.address) formData['address']['address'] = data.address;
    if (data?.address) formData['address']['country'] = data.country;
    if (data?.address) formData['address']['city'] = data.city;
    if (data?.address) formData['address']['state'] = data.state;
    return id
      ? http.put(`/user/cp/org/${orgId}/branches?id=${id}`, formData)
      : http.post(`/user/cp/org/${orgId}/branches`, formData);
  }
  update(id, data) {
    const formData = {
      ..._.pick(data, ['name', 'description', 'dateCreated']),
      address: {},
    };
    if (data?.selectedEmployee)
      formData['managerId'] = data?.selectedEmployee?.id;
    if (data?.address) formData['address']['address'] = data.address;
    if (data?.address) formData['address']['country'] = data.country;
    if (data?.address) formData['address']['city'] = data.city;
    if (data?.address) formData['address']['state'] = data.state;
    return http.put(`/user/cp/org/${orgId}/branches?id=${id}`, formData);
  }
  delete(id) {
    return http.delete(`/user/cp/org/${orgId}/branches?id=${id}`);
  }
  get() {
    return http.get(`/user/cp/org/${orgId}/branches`);
  }
  getOne(id) {
    return http.get(`/user/cp/org/${orgId}/branches/single?id=${id}`);
  }
}

export class Department {
  create(data, id = '') {
    const formData = { ...data, managerId: data?.selectedEmployee?.id };
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) delete formData[key];
    });
    return id
      ? http.put(`/user/cp/org/${orgId}/departments?id=${id}`, formData)
      : http.post(`/user/cp/org/${orgId}/departments`, formData);
  }
  update(id, data) {
    const formData = { ...data, managerId: data?.selectedEmployee?.id };
    return http.put(`/user/cp/org/${orgId}/departments?id=${id}`, formData);
  }
  delete(id) {
    return http.delete(`/user/cp/org/${orgId}/departments?id=${id}`);
  }
  get() {
    return http.get(`/user/cp/org/${orgId}/departments`);
  }
  getOne(id) {
    return http.get(`/user/cp/org/${orgId}/departments/single?id=${id}`);
  }
}

function orgFormData(data) {
  const formData = new FormData();
  formData.append('name', data?.name);
  formData.append('phone', data?.phone);
  formData.append('email', data?.email);
  formData.append('currency', data?.currency);
  if (data?.image) formData.append('logoUpload', data.image);
  return formData;
}

export default new User();
